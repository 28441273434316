import { useState, useEffect, useContext } from "react";
import { message, Select } from 'antd';
import { createFun, createEat, createPlay } from '../../component/useContext';
import { baseProvinces, hostUrl } from '../../lib/base/base';
import './lucky-wheel.css';
import Axios from 'axios';

interface Data {
    id: number,
    luckyName: string,
    probability: number,
}

export const LuckyWheel = (props: any) => {
    const [selected, setSelected] = useState<Number>();
    const [tableLink, setTableLink] = useState('吃啥');
    const [isRunning, setIsRunning] = useState(false);
    const [queryValue, setQueryValue] = useState('');
    const [luckyData, setLuckyData] = useState<[Data]>([{
        id: 0,
        luckyName: "",
        probability: 0
    }]);
    const [luckyVale, setLuckyValue] = useState<string>('');
    const [selectClick, setSelectClick] = useState('北京');
    const { setEditLucky } = useContext(createFun);
    const setProvEat = useContext(createEat);
    const setProvPlay = useContext(createPlay);
    const [loadings, setLoadings] = useState(0);



    useEffect(() => {
        let timerId: string | number | NodeJS.Timer | undefined;
        if (isRunning) {
            timerId = setInterval(() => {
                const randomIndex = Math.floor(Math.random() * luckyData.length);
                setSelected(randomIndex);
            }, 300);
        }
        return () => clearTimeout(timerId);
    }, [isRunning, selected]);

    useEffect(() => {
        const { items, tableLink } = props;
        setTableLink(tableLink);
        setLuckyData(items);
    }, [props]);
    const handleClick = () => {
        if (loadings === 1) {
           return message.error('请等待这次抓阄完成');
        }
        setLoadings(1);
        if (isRunning) return;
        setIsRunning(true);
        setTimeout(() => {
            setIsRunning(false);
            const randomIndex = selectWinner(luckyData);
            // eslint-disable-next-line array-callback-return
            luckyData.find((item) => {
                if (item.id === randomIndex) {
                    setLuckyValue(item.luckyName);
                    setQueryValue('https://www.sogou.com/web?query='+item.luckyName)
                }
            })
            setSelected(randomIndex);
            setLoadings(0);
        }, 5000);
    };
    // 定位信息
    const SelectClick = (vale: string) => {
        setSelectClick(vale);
        setProvEat(vale);
        setProvPlay(vale);
        setSelected(undefined);
    }
    const luckyWheelEdit = () => {
        setEditLucky(0)
    }
    // 按奖品中奖几率抽奖逻辑  
    const selectWinner = (itemsArr: { probability: any; }[]) => {
        const probabilities = itemsArr.map((item: { probability: any; }) => item.probability);
        const sum = probabilities.reduce((acc: any, val: any) => acc + val, 0);
        const normalizedProbabilities = probabilities.map((p) => p / sum);

        let cumulativeProbability = 0;
        const cumulativeProbabilities = normalizedProbabilities.map((p) => {
            cumulativeProbability += p;
            return cumulativeProbability;
        });

        const randomNumber = Math.random();
        const selectedIndex = cumulativeProbabilities.findIndex(
            (cp) => randomNumber <= cp
        );

        return selectedIndex;
    }

    return (<>

        <div className='lucky-wheel' style={{
            marginTop: '1em',
        }}>
            {luckyData.map((item) => (
                <div
                    key={item.id}
                    className={`lucky-wheel-item ${selected === item.id ? "selected" : ""
                        }`}
                    style={{
                        marginLeft: '1em',
                    }}
                >
                    {item.luckyName}
                 
                </div>
            ))}
        </div>
        <div className="lucky-click">
            {tableLink === '抓阄' ? <div className="lucky-wheel-edit" onClick={luckyWheelEdit}>编辑</div> : ''}
            {tableLink !== '抓阄' ? <Select
                style={{ width: 120, height: 40 }}
                value={selectClick}
                disabled={loadings === 0 ? false : true}
                onChange={SelectClick}
                options={baseProvinces.map((item) => ({ label: item, value: item }))}
            /> : ''}
            <div className='lucky-wheel-button' onClick={handleClick}>
                {isRunning ? `${tableLink}中..` : tableLink}
            </div>
        </div>
        {luckyVale !== '' ? <div className="lucky-vale">选中了：<span className="span-lucky">{luckyVale}</span></div> : ''}
    </>
    );
};


import { Link, Outlet } from 'react-router-dom';
import { logImage } from './img';
import './index.css';
import { useEffect, useState } from 'react';
import Advertise from './component/ad';
import { hostUrl } from '../src/lib/base/base';
import  Axios  from 'axios';

const App = () => {
  const [pre, setPre] = useState(false);
  const [usePv, setUsePv] = useState<number>();
  const linkEat = () => {
    setPre(false);
    const eatCss = document.querySelector('#prefer');
    const palyCss = document.querySelector('#paly');
    const giftCss = document.querySelector('#gift');
    const preferCss = document.querySelector('#eat');
    if (eatCss) {
      (eatCss as HTMLElement).style.backgroundColor = '#e8e8fd';
      (palyCss as HTMLElement).style.backgroundColor = '';
      (giftCss as HTMLElement).style.backgroundColor = '';
      (preferCss as HTMLElement).style.backgroundColor = '';
      (eatCss as HTMLElement).style.color = '#ff5000';
      (palyCss as HTMLElement).style.color = '';
      (giftCss as HTMLElement).style.color = '';
      (palyCss as HTMLElement).style.color='';
    }
  }
  const linkPlay = () => {
    setPre(true);
    const eatCss = document.querySelector('#prefer');
    const palyCss = document.querySelector('#paly');
    const giftCss = document.querySelector('#gift');
    const preferCss = document.querySelector('#eat');
    if (palyCss) {
      (eatCss as HTMLElement).style.backgroundColor = '';
      (palyCss as HTMLElement).style.backgroundColor = '#e8e8fd';
      (giftCss as HTMLElement).style.backgroundColor = '';
      (preferCss as HTMLElement).style.backgroundColor = '';
      (eatCss as HTMLElement).style.color = '';
      (palyCss as HTMLElement).style.color = '#ff5000';
      (giftCss as HTMLElement).style.color = '';
      (palyCss as HTMLElement).style.color='';
    }
  }
  const linkGift = () => {
    setPre(true);
    const eatCss = document.querySelector('#prefer');
    const palyCss = document.querySelector('#paly');
    const giftCss = document.querySelector('#gift');
    const preferCss = document.querySelector('#eat');
    if (giftCss) {
      (eatCss as HTMLElement).style.backgroundColor = '';
      (palyCss as HTMLElement).style.backgroundColor = '';
      (giftCss as HTMLElement).style.backgroundColor = '#e8e8fd';
      (preferCss as HTMLElement).style.backgroundColor = '';
      (eatCss as HTMLElement).style.color = '';
      (palyCss as HTMLElement).style.color = '';
      (giftCss as HTMLElement).style.color = '#ff5000';
      (palyCss as HTMLElement).style.color='';
      
    }
  }
  const linkPrefer = ()=>{
    setPre(true);
    const eatCss = document.querySelector('#prefer');
    const palyCss = document.querySelector('#paly');
    const giftCss = document.querySelector('#gift');
    const preferCss = document.querySelector('#eat');
    if (preferCss) {
      (eatCss as HTMLElement).style.backgroundColor = '';
      (palyCss as HTMLElement).style.backgroundColor = '';
      (giftCss as HTMLElement).style.backgroundColor = '';
      (eatCss as HTMLElement).style.color = '';
      (palyCss as HTMLElement).style.color = '';
      (giftCss as HTMLElement).style.color = '';
      (palyCss as HTMLElement).style.color='#ff5000';
      (preferCss as HTMLElement).style.backgroundColor = '#e8e8fd';
    }
  }
  useEffect(()=>{
    const linkUrl = window.location.href;
    if (linkUrl.indexOf('/paly') !== -1){
      linkPlay()
    }
    if (linkUrl.indexOf('/gift') !== -1) {
      linkGift()
    }
    if (linkUrl.indexOf('/eat') !== -1) {
      linkPrefer()
    }
    setTimeout(()=>{
      userUseUpClick();
    },10)
  },[]);
  // 每日用户上报
  const userUseUpClick = async () => {
    const res = await Axios.get(`${hostUrl}/api/userUse`, {
        headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache'
        }
    });
    const clickPV = res.data.data.userUse?.click_pv;
    const click = Number(clickPV) + 1;
    setUsePv(click);
    const data = {
        click_pv: click
    }
    await Axios({
        method: 'post',
        url: `${hostUrl}/api/userUseUpdate`,
        params: data
    });
}
  useEffect(()=>{
    
  },[])
  return (
    <div className='base'>
      <div className="nav-head">
      <div className='head-log'>
          <div className='logImage'>
            <img src={logImage} alt='哇哇有趣' style={{ width: '100%', height: '100%' }} />
          </div>
      </div>
      <div className='navigation-head'>
      <div className='left-navigation' style={{backgroundColor: '#e8e8fd'}} id='prefer' onClick={linkEat}><Link className='linkD' to='/'>特价惠</Link></div>
      <div className='left-navigation' id='paly'><Link className='linkD' to='/paly' onClick={linkPlay}>去哪玩</Link></div>
      <div className='left-navigation' id='eat'><Link className='linkD' to='/eat' onClick={linkPrefer}>去吃啥</Link></div>
      <div className='left-navigation' id='gift'><Link className='linkD' to='/gift' onClick={linkGift}>自己选</Link></div>
      </div>
      </div>
      <div className="nav-context">
        <div className='context-operate'>
          <div className='context-outer'>
          <div style={{
            width: '15em',
            marginLeft: '1em',
        }}>趣味生活玩家:<span style={{ color: '#0269c8', fontSize: '19px', marginLeft: '5px' }}>{usePv}</span></div>
            {/**Outlet组件内容暂时**/}
            <Outlet></Outlet>
          </div>
          {pre ? <div className='ad'>
            <h4>好物推荐</h4>
            <Advertise />
          </div> : ''}
        
        </div>
        <div className='font'>
        <p>
          <a href='https://qm.qq.com/q/Rz4piS4fmM'>站长反馈</a>
          </p>
          <p>工具箱：
            <a href='https://tool.browser.qq.com/' target="_blank" rel="noreferrer">帮小忙</a>|
            <a href='https://tool.lu/' target="_blank" rel="noreferrer">在线工具</a>|
            <a href='https://www.67tool.com/' target="_blank" rel="noreferrer">即时</a>|
            <a href='https://tools.fun/' target="_blank" rel="noreferrer">云科信息</a>|
            <a href='https://www.toolhelper.cn/' target="_blank" rel="noreferrer">锤子在线</a>|
            <a href='https://www.iamwawa.cn/' target='_blank' rel="noreferrer">蛙蛙</a>
          </p>
          <p>备案号：<a href='https://beian.miit.gov.cn/' target="_blank" rel="noreferrer">蜀ICP备2023005707号-1</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;

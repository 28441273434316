// 1.引入一个方法
import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import Eat from '../component/eat';
import Gift from '../component/gift';
import Play from '../component/play';
import Preferential from '../component/preferential';
import NoPage from '../component/nopage';
// createBrowserRouter ->history模式路由
const routers = createBrowserRouter([{
    path: '/',
    element: <App />,
    children: [{
        index: true,
        element: <Preferential/>,
    }, {
        path: 'gift',
        element: <Gift />,
    }, {
        path: 'paly',
        element: <Play />
    },{
        path: 'eat',
        element: <Eat />
    }, {
        path: '*',
        element: <NoPage/>
    }
    ]
}]);

export default routers